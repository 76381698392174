<script>
import moment from "moment";
import SvgIcon from "../common/SvgIcon.vue";
moment.locale("id");
export default {
  components: { SvgIcon },
  name: "AssessmentCard",
  props: {
    assessment: Object,
  },
  computed: {
    isStatusOpen() {
      return this.assessment?.status === "open" ? true : false
    },
    quota() {
      const availableQuota = this.assessment.quota - this.assessment.quota_used;
      return +this.assessment?.quota > 0
        ? availableQuota > 0
          ? availableQuota
          : 0
        : 0;
    },
  },
  methods: {
    convertDate(date) {
      return moment(date).format("DD MMM YYYY");
    },
  },
};
</script>
<template>
  <div class="assessment-card">
    <div class="position-relative overflow-hidden rounded-lg">
      <img
        src="@/assets/announcement/penilaian-buku-rekomendasi.jpeg"
        alt="Banner"
        style="object-fit: cover;max-height: 250px;"
      />
      <div class="assessment-card--image-badge text-primary bg-primary-50">
        {{ assessment.slug }}
      </div>
      <div
        class="assessment-card--image-overlay bg-grey-700/70"
        v-if="!isStatusOpen"
      ></div>
    </div>
    <div class="assessment-card__body">
      <div class="d-flex align-items-center">
        <span
          class="assessment-card__badge"
          :class="{ 'assessment-card__badge--closed': !isStatusOpen }"
        >
          Pendaftaran {{ isStatusOpen ? "Dibuka" : "Ditutup" }}
        </span>
        <span class="pl-3 text-sm text-muted">
          {{ convertDate(assessment.date_start) }}
        </span>
      </div>
      <p class="h5 font-weight-bold">{{ assessment.title }}</p>
      <div class="assessment-card__info">
        <div class="d-flex align-items-start">
          <svg-icon name="books2" class="assessment-card__info-icon" />
          <div>
            <div class="text-grey-500 text-nowrap">Sisa Kuota</div>
            <div class="font-weight-semibold" v-if="assessment.quota == 0">
              -
            </div>
            <div class="font-weight-semibold" v-else>{{ quota }} Buku</div>
          </div>
        </div>
        <div class="d-flex align-items-start">
          <svg-icon name="file-pdf2" class="assessment-card__info-icon" />
          <div>
            <div class="text-grey-500">File</div>
            <div v-if="assessment.slug == 'btp'">
              <div v-if="assessment.id != 14">
                <a
                  href="https://static.buku.kemdikbud.go.id/content/undang-undang/instrumen-BTP-materi-pembelajaran-2024.pdf"
                  target="_blank"
                  class="text-primary d-block"
                >
                  Lihat instrumen BTP
                </a>
                <a
                  href="https://docs.google.com/document/d/1EJrhC1KmlTAzDf55C7NW_MpSs3Wu9of6/edit?usp=sharing&ouid=114512911415227814558&rtpof=true&sd=true"
                  target="_blank"
                  class="text-primary"
                >
                  Lihat Swa Asesmen
                </a>
              </div>
              <a
                v-if="assessment.id == 14"
                href="https://drive.google.com/file/d/1jXng7pfsAgzPztiPvZBmFzj_DMA-i0SG/view?usp=sharing"
                target="_blank"
                class="text-primary"
              >
                Lihat instrumen BTP untuk SMK
              </a>
            </div>

            <a
              v-if="assessment.slug == 'bnt'"
              href="https://drive.google.com/drive/folders/1UF4dcu4p4Ro_n2O7GNvuONfLelBwsHG2?usp=share_link"
              target="_blank"
              class="text-primary"
              >Lihat instrumen BNT</a
            >
          </div>
        </div>
      </div>
      <router-link
        :to="`/user/penilaian/${assessment.slug}?event_id=${assessment.id}`"
        class="btn btn-block btn-secondary "
        v-if="isStatusOpen"
      >
        <span>Daftarkan Buku</span>
      </router-link>
    </div>
  </div>
</template>
<style scoped lang="scss">
.assessment-card {
  border-radius: 0.75rem;
  padding: 0.5rem;
  border: 1px solid #f2f2f2;

  & img {
    width: 100%;
    border-radius: 0.5rem;
    transition: all 0.3s;
    &:hover {
      scale: 1.1;
    }
  }

  &--image-badge {
    position: absolute;
    top: 0.75rem;
    left: 0.75rem;
    text-transform: uppercase;
    font-weight: 800;
    border-radius: 1rem;
    padding: 0.2rem 1rem;
    z-index: 10;
  }

  &--image-overlay {
    position: absolute;
    border-radius: 0.5rem;
    inset: 0;
    pointer-events: none;
  }

  &__badge {
    padding: 0.25rem 1rem;
    font-size: 12px;
    border-radius: 2rem;
    color: #219653;
    background: #eafff3;
    font-weight: 600;
    &--closed {
      background: #ffe3e3;
      color: #eb5757;
    }
  }
  &__body {
    & > * {
      margin-top: 0.75rem;
    }
  }

  &__info {
    background: var(--grey-100);
    padding: 0.5rem 4px;
    display: flex;
    justify-content: space-around;
    gap: 1rem;
    &-icon {
      height: 1.75rem;
      width: 1.5rem !important;
      & + div {
        flex-grow: 1;
        padding-left: 4px;
        font-size: 12px;
      }
    }
  }
}
</style>
